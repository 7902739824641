
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const ServicesPage = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        height: 'var(--router-height)',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',  // Ensure the content is stacked vertically
        overflowY: 'auto',
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in WebKit browsers (Chrome, Safari)
        },
      }}
    >
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h3" align="center" color='white' gutterBottom>
          SERVICES
        </Typography>
      </Box>

      {/* Flex container for the grid with padding bottom */}
      

      <Box sx={{ flex: 1, display: 'flex', alignItems: 'stretch', paddingBottom: '40px' }}>
        <Grid container spacing={4} alignItems="stretch">
          {/* Service 1 */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              sx={{
                height: '100%',
                backgroundColor: 'var(--background-gray)',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ color: 'white' }}>
                <Typography variant="h4" gutterBottom color="var(--accent-color)">
                  Business Development Outsourcing
                </Typography>
                <ul
                  style={{
                    listStyleType: 'disc',
                    paddingLeft: '20px',
                    marginTop: '16px',
                    lineHeight: '1.8',
                    fontSize: '20px',
                  }}
                >
                  <li style={{marginBottom: '16px'}}>
                    We become your business development and sales team, no commitment, no fees.
                  </li>
                  <li>
                    We operate purely on commission, and if you don’t win, neither do we.
                  </li>
                </ul>
              </Box>
            </Paper>
          </Grid>

          {/* Service 2 */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              sx={{
                height: '100%',
                backgroundColor: 'var(--background-gray)',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ color: 'white' }}>
                <Typography variant="h4" gutterBottom color="var(--accent-color)">
                  Enterprise Relationship Fostering and Management
                </Typography>
                <ul
                  style={{
                    listStyleType: 'disc',
                    paddingLeft: '20px',
                    marginTop: '16px',
                    lineHeight: '1.8',
                    fontSize: '20px',
                  }}
                >
                  <li style={{marginBottom: '16px'}}>
                    Our extensive relationship network becomes yours. If you need an introduction or
                    to talk to someone we know, we’ll facilitate it.
                  </li>
                  <li>
                    Any new relationships you require, we’ll break down the obstacles.
                  </li>
                </ul>
              </Box>
            </Paper>
          </Grid>

          {/* Service 3 */}
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              sx={{
                height: '100%',
                backgroundColor: 'var(--background-gray)',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ color: 'white' }}>
                <Typography variant="h4" gutterBottom color="var(--accent-color)">
                  Product / Service Analysis & Selection
                </Typography>
                <ul
                  style={{
                    listStyleType: 'disc',
                    paddingLeft: '20px',
                    marginTop: '16px',
                    lineHeight: '1.8',
                    fontSize: '20px',
                  }}
                >
                  <li style={{marginBottom: '16px'}}>
                    For clients requiring particular services or products, we can assess your
                    requirements and look into our vendor & startup ecosystem to find you the best
                    fit.
                  </li>
                  <li>
                    If we don’t have anyone to provide you with what you need, we’ll go digging free
                    of charge.
                  </li>
                </ul>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ServicesPage;

