import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Box,
  Select,
  MenuItem,
  Container,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
} from '@mui/material';

const Contact = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    requestType: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    type: false,
    message: false,
  });

  const [formError, setFormError] = useState('');

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const validateName = (name) => {
    // Basic name validation
    return name.trim().length > 0;
  };

  const validateEmail = (email) => {
    // Basic email validation using regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Basic phone validation using regex
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const validateMessage = (message) => {
    // Basic message validation
    return message.trim().length > 0;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let valid = true;

    if (!validateName(formData.name)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: true,
      }));
      valid = false;
    }

    if (!validateEmail(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: true,
      }));
      valid = false;
    }

    if (!validatePhone(formData.phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: true,
      }));
      valid = false;
    }

    if (!validateMessage(formData.message)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: true,
      }));
      valid = false;
    }

    if (valid) {
      setLoading(true);

      try {
        await onSubmit(formData);
        setFormError('');
        setSubmitted(true);
      } catch (error) {
        console.error('Submission error:', error);
        setFormError('An unexpected error occurred. Please try again later.');
      }

      setLoading(false);
    }
  };

  if (submitted) {
    return (
      <Container>
        <Typography variant="h4" align="center" sx={{ mt: 4 }}>
          Thank you for contacting us!
        </Typography>
        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
          We will get back to you as soon as possible.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="Full Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          margin="dense" // Reduced margin
          size="small"   // Reduced size
          error={errors.name}
          helperText={errors.name ? 'Name cannot be empty' : ''}
        />
        <TextField
          fullWidth
          label="Email Address"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleInputChange}
          margin="dense"
          size="small"
          error={errors.email}
          helperText={errors.email ? 'Invalid email format' : ''}
        />
        <TextField
          fullWidth
          label="Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          margin="dense"
          size="small"
          error={errors.phone}
          helperText={errors.phone ? 'Invalid phone format' : ''}
        />
        <FormControl fullWidth margin="dense" size="small">
          <InputLabel>Type of Request</InputLabel>
          <Select
            name="requestType"
            value={formData.requestType}
            label="Type of Request"
            onChange={handleInputChange}
          >
            <MenuItem value="General Inquiry">General Inquiry</MenuItem>
            <MenuItem value="Support">Support</MenuItem>
            <MenuItem value="Feedback">Feedback</MenuItem>
            {/* Add more request types as needed */}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Message"
          name="message"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleInputChange}
          margin="dense"
          size="small"
          error={errors.message}
          helperText={errors.message ? 'Message cannot be empty' : ''}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            size="large"
          >
            {loading ? <CircularProgress size={24} /> : 'Send Message'}
          </Button>
          {formError && (
            <Typography color="error" sx={{ mt: 2 }}>
              {formError}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Contact;

