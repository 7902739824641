
import React, { useState, useEffect } from 'react';
import { Typography, AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from '../assets/logo_two.PNG';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleResize = () => {
      if (!isMobile) {
        setDrawerOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setDrawerOpen(false); // Close drawer on mobile after selection
    }
  };

  const drawerList = (
    <div>
      <List>
        <ListItem>
          <ListItemIcon>
            <AccountCircleIcon style={{ fontSize: 40 }} />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="h6">User Logo</Typography>} />
        </ListItem>
        <ListItem button onClick={() => scrollToSection('hero')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => scrollToSection('services')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem button onClick={() => scrollToSection('how')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="How it Works" />
        </ListItem>
        <ListItem button onClick={() => scrollToSection('about')}>
          <ListItemIcon><InfoIcon /></ListItemIcon>
          <ListItemText primary="About Us" />
        </ListItem>
        <ListItem button onClick={() => scrollToSection('contact')}>
          <ListItemIcon><ContactsIcon /></ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
      <Divider />
    </div>
  );

  return (
    <AppBar position="fixed" color="primary" sx={{ height: 'var(--header-height)', width: '100%', zIndex: (theme) => theme.zIndex.drawer + 1, borderBottom: `2px solid ${theme.palette.secondary.main}`}}>
      <Toolbar sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <IconButton color="secondary" onClick={() => scrollToSection('hero')} sx={{ p: 0 }}>
          <img src={logo} alt="Logo" style={{ height: 'calc(var(--header-height) - 20px)' }} />
        </IconButton>
        {isMobile ? (
          <>
            <IconButton color="secondary" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </>
        ) : (
          <div>
            <Button color="secondary" onClick={() => scrollToSection('hero')}>Home</Button>
            <Button color="secondary" onClick={() => scrollToSection('services')}>Services</Button>
            <Button color="secondary" onClick={() => scrollToSection('how')}>How it Works</Button>
            <Button color="secondary" onClick={() => scrollToSection('about')}>About Us</Button>
            <Button color="secondary" onClick={() => scrollToSection('contact')}>Contact</Button>
          </div>
        )}
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawerList}
      </Drawer>
    </AppBar>
  );
}

export default Navbar;

