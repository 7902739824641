import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../environments/FirebaseConfig';

// CONTACT US FUNCTIONS
// ---------------------------------------------------------------------------------------------------------------------
export const createContactUsMessage = async (messageData) => {
    const messagesRef = collection(db, 'contactUs');

    await setDoc(doc(messagesRef), {
        type: messageData.requestType,
        name: messageData.name,
        email: messageData.email,
        phone: messageData.phone,
        message: messageData.message,
    });
}
