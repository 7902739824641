import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBsxGO2Ef7KvFOTC1qrCAcZ8ftBUKN--zc",
  authDomain: "hivenet-site.firebaseapp.com",
  projectId: "hivenet-site",
  storageBucket: "hivenet-site.appspot.com",
  messagingSenderId: "533801993303",
  appId: "1:533801993303:web:54e69d718491d0c5d88f12",
  measurementId: "G-0NPFSVE5HW"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };