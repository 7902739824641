
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HeroImg from '../assets/pexels-artempodrez-5716001_reduced.jpg'

const HeroPage = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        height: 'var(--router-height)',
        backgroundColor: 'black',
        display: 'flex',
        //flexDirection: 'column', // Stack content vertically
        justifyContent: 'center',
        overflowY: 'auto',
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in WebKit browsers (Chrome, Safari)
        },
      }}
    >
      {/* Flex container for the grid with padding at the bottom */}
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'stretch', padding: '20px 0px 20px 0px' }}>
        <Grid container spacing={4} alignItems="stretch">
          {/* Left Side - Text Content */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                height: '100%', // Ensures the Paper fills the height of the Grid item
                backgroundColor: 'black',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography 
                variant="h3"  // Larger text size
                gutterBottom 
                color="var(--accent-color)" 
                sx={{ fontSize: 'h3'}}//{ xs: '32px', sm: '48px', md: '64px' } }} // Responsive text sizing
              >
                CONNECT THROUGH THE HIVE
              </Typography>
              <Box sx={{ color: 'white', maxWidth: '600px' }}>
                <Typography variant="body1" paragraph sx={{ lineHeight: '1.8', fontSize: '20px' }}>
                  Join the platform that connects startups to enterprise clients, and give firms access to the best of breed, up and coming products and services.
                </Typography>
                <Typography variant="body1" paragraph sx={{ lineHeight: '1.8', fontSize: '20px' }}>
                  A one stop enterprise services network and startup sales engine in one.
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Right Side - Image */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                height: '100%', // Ensures the Paper fills the height of the Grid item
                backgroundColor: 'black', // Black background to match the rest
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
              }}
            >
              <img 
                src={HeroImg} 
                alt="Hero" 
                style={{ 
                  width: '100%',
                  height: 'auto',// NOTE: if you want this to be sqlare change it to 100%
                  //maxWidth: '100%',
                  //maxHeight: '100%',
                  objectFit: 'cover',
                  margin: '10px',
                  borderRadius: '8px' }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HeroPage;

