
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AboutImg from '../assets/pexels-olly-3760069_reduced.jpg';

const AboutPage = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        height: 'var(--router-height)',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',  // Ensure the content is stacked vertically
        overflowY: 'auto',
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in WebKit browsers (Chrome, Safari)
        },
      }}
    >
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h3" align="center" color="white" gutterBottom>
          ABOUT US
        </Typography>
      </Box>

      {/* Flex container for the grid with padding bottom */}
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'stretch', paddingBottom: '40px' }}>
        <Grid container spacing={4} alignItems="stretch">
          {/* Left Side - text */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                height: '100%', // Ensures the Paper fills the height of the Grid item
                backgroundColor: 'var(--background-gray)',
                padding: '20px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h4" gutterBottom color="var(--accent-color)">
                Our Mission
              </Typography>
              <Box sx={{ color: 'white' }}>
                <Typography variant="body1" paragraph sx={{ lineHeight: '1.8', fontSize: '20px' }}>
                  We are dedicated to delivering exceptional services that empower businesses to thrive in competitive markets. Our mission is to provide innovative solutions, foster meaningful relationships, and ensure our clients achieve their goals.
                </Typography>
                <Typography variant="body1" paragraph sx={{ lineHeight: '1.8', fontSize: '20px' }}>
                  With a deep understanding of market dynamics and a commitment to excellence, we strive to be your trusted partner in business development, relationship management, and product selection.
                </Typography>
                <Typography variant="body1" paragraph sx={{ lineHeight: '1.8', fontSize: '20px' }}>
                  Our team is comprised of industry experts with a passion for helping businesses succeed. We operate with integrity, transparency, and a relentless drive to deliver results.
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/* Right Side - Image */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                height: '100%', // Ensures the Paper fills the height of the Grid item
                backgroundColor: 'var(--background-gray)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
              }}
            >
              <img 
                src={AboutImg} 
                alt="About Us" 
                style={{
                  width: '100%',
                  height: '100%',
                  //maxWidth: '100%',
                  //maxHeight: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutPage;

