
import React from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material';
import Contact from '../components/Contact';
import { createContactUsMessage } from '../components/FirestoreFunctions';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ContactPage = () => {
  const handleFormSubmit = async (formData) => {
    // Logic to handle form submission
    console.log('Form Data:', formData);
    await createContactUsMessage(formData);
  };

  return (
    <Container
      maxWidth="100%"
      sx={{
        height: 'var(--router-height)',
        backgroundColor: 'black',
        padding: '20px',
        overflowY: 'auto',
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in WebKit browsers (Chrome, Safari)
        },
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="stretch"
        style={{ minHeight: 'calc(var(--router-height) - 10px)', padding: '10px' }}
      >
        {/* Left Side - Contact Details */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: '30px',
              backgroundColor: 'var(--background-light)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
            }}
            elevation={3}
          >
            <Typography variant="h4" gutterBottom color="primary">
              Contact Us
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<strong>Address:</strong>}
                  secondary=
                    "Level 5, 600 Murray Street, West Perth WA 6005"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<strong>Email:</strong>}
                  secondary={
                    <Link
                      href="mailto:admin@hivenet.com.au"
                      color="inherit"
                      underline="hover"
                    >
                      admin@hivenet.com.au
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<strong>Phone:</strong>}
                  secondary={
                    <Link
                      href="tel:+61421224166"
                      color="inherit"
                      underline="hover"
                    >
                      +61 421 224 166
                    </Link>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AccessTimeIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<strong>Office Hours:</strong>}
                  secondary="Monday - Friday: 9:00 AM - 5:30 PM"
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Right Side - Contact Form */}
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: '30px',
              backgroundColor: 'var(--background-light)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
            }}
            elevation={3}
          >
            <Typography variant="h4" gutterBottom color="primary">
              Get in Touch
            </Typography>
            <Box sx={{ width: '100%', flexGrow: 1 }}>
              <Contact onSubmit={handleFormSubmit} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactPage;

