
import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Hexagon from '../components/HexContainer.js';

const HowPage = () => {
  return (
    <Container
      maxWidth="90%"
      sx={{
        backgroundColor: 'var(--background-gray)',
        height: 'var(--router-height)',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: '50px',
        paddingTop: '20px',
        paddingBottom: '50px',
        position: 'relative',
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in WebKit browsers (Chrome, Safari)
        },
      }}
    >
      {/* Title */}
      <Box
        sx={{
          width: '100%',
          fontSize: { xs: '32px', sm: '48px', md: '64px' } ,
          //fontWeight: 'bold',
          lineHeight: 1.2,
          height: 'auto',
          //whiteSpace: 'nowrap', // Ensure the text stays on a single line
          textAlign: 'center', // Center the title
          color: 'white',
          paddingBottom: '30px',
        }}
      >
        HOW DOES IT WORK?
      </Box>

      {/* Hexagons */}
      <Box
        sx={{
          transform: {
            sm: 'none',
            md: 'translateY(-60px)',
          },
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: {
              sm: 'scale(1.1)',
              md: 'translateY(-40px) scale(1.1)',
            },
          },
        }}
      >
        <Hexagon
          number="1"
          text="We utilise our existing network of contacts as well as expand to new prospects."
          size={{
            xs: '150px',  // Small hexagon for small screens
            sm: '200px',  // Medium hexagon for medium screens
            md: '250px',  // Larger hexagon for larger screens
          }}
        />
      </Box>
      <Box
        sx={{
          transform: {
            sm: 'none',
            md: 'translateY(100px)',
          },
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: {
              sm: 'scale(1.1)',
              md: 'translateY(80px) scale(1.1)',
            },
          },
        }}
      >
        <Hexagon
          number="2"
          text="Once the discovery period of 6 weeks is over, if you do not wish to continue with our service, we finish up here."
          size={{
            xs: '150px',  // Small hexagon for small screens
            sm: '200px',  // Medium hexagon for medium screens
            md: '250px',  // Larger hexagon for larger screens
          }}
        />
      </Box>
      <Box
        sx={{
          transform: {
            sm: 'none',
            md: 'translateY(-60px)',
          },
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: {
              sm: 'scale(1.1)',
              md: 'translateY(-40px) scale(1.1)',
            },
          },
        }}
      >
        <Hexagon
          number="3"
          text="We report back regularly on progress, any sale we make we record for revenue recognition."
          size={{
            xs: '150px',  // Small hexagon for small screens
            sm: '200px',  // Medium hexagon for medium screens
            md: '250px',  // Larger hexagon for larger screens
          }}
        />
      </Box>
      <Box
        sx={{
          transform: {
            sm: 'none',
            md: 'translateY(100px)',
          },
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: {
              sm: 'scale(1.1)',
              md: 'translateY(80px) scale(1.1)',
            },
          },
        }}
      >
        <Hexagon
          number="4"
          text="If you choose to continue with our service, we will sign you up to the perpetual contract and we agree new terms that suit you and your business."
          size={{
            xs: '150px',  // Small hexagon for small screens
            sm: '200px',  // Medium hexagon for medium screens
            md: '250px',  // Larger hexagon for larger screens
          }}
        />
      </Box>
    </Container>
  );
};

export default HowPage;

