import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';

import Navbar from './components/Navbar';
import MaintenanceNavbar from './components/MaintenanceNavbar';
import Footer from './components/Footer';

// import LoginPage from './pages/LoginPage';

import MaintenancePage from './pages/MaintenancePage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage'; // 404 Page

function App() {
  const maintenanceMode = false; // Toggle this manually for now

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {maintenanceMode ? <MaintenanceNavbar /> : <Navbar />}
        <div className="app-container">
          <div className="router-content">
            <Routes>
              {maintenanceMode ? (
                <Route path="*" element={<MaintenancePage />} />
              ) : (
                <>
                  {/* Home Page */}
                  <Route exact path="/" element={<HomePage />} />

                  {/* <Route path="/about" element={<AboutPage />} /> */}
                  {/* <Route path="/contact" element={<ContactPage />} /> */}
                  {/* <Route path="/user" element={<UserPage />} /> */}
                  {/* <Route path="/login" element={<LoginPage />} /> */}
                  {/* <Route path="/search" element={<SearchPage />} /> */}
                  {/* <Route path="/services" element={<ServicesPage />} /> */}
                  {/* <Route path="/how" element={<HowPage />} /> */}
                  {/* <Route path="/view/:id" element={<ViewPage />} /> */}

                  {/* Fallback for unmatched routes */}
                  <Route path="*" element={<NotFoundPage />} />
                </>
              )}
            </Routes>
          </div>
        </div>
        <Footer className="footer" />
      </Router>
    </ThemeProvider>
  );
}

export default App;
