
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function Footer() {
  const theme = useTheme();

  return (
    <footer style={{
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      textAlign: 'center',
      height: 'var(--footer-height)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: `2px solid ${theme.palette.secondary.main}`,
      boxSizing: 'border-box', // Ensures border doesn't increase height
    }}>
      <Container>
        <Typography variant="body1">
          © {new Date().getFullYear()} HiveNet. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;

