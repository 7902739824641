import { createTheme } from '@mui/material/styles';

// Utility function to get the value of a CSS variable
const getCssVariableValue = (variableName) => {
  const style = getComputedStyle(document.documentElement);
  return style.getPropertyValue(variableName).trim();
};

// Function to adjust viewport height and update CSS variables accordingly
export function adjustViewportHeight() {
  const actualVh = (window.visualViewport ? window.visualViewport.height : window.innerHeight) * 0.01;
  document.documentElement.style.setProperty('--actual-vh', `${actualVh}px`);

  // Recalculate router height based on the updated actual viewport height
  const headerHeight = getCssVariableValue('--header-height');
  const footerHeight = getCssVariableValue('--footer-height');
  const routerHeight = `calc(100 * var(--actual-vh) - ${headerHeight} - ${footerHeight})`;
  document.documentElement.style.setProperty('--router-height', routerHeight);
}

// Set up event listeners to adjust height on resize and orientation change
window.addEventListener('resize', adjustViewportHeight);
window.addEventListener('orientationchange', adjustViewportHeight);

// Call adjustViewportHeight on load
adjustViewportHeight();

// Define the MUI theme, using CSS variables for colors
const theme = createTheme({
  palette: {
    primary: {
      main: getCssVariableValue('--primary-color'),
    },
    secondary: {
      main: getCssVariableValue('--secondary-color'),
      background: getCssVariableValue('--background-sec-color'),
    },
    accent: {
      main: getCssVariableValue('--accent-color'),
    },
    background: {
      default: getCssVariableValue('--background-color'),
    },
    text: {
      primary: getCssVariableValue('--text-color'),
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          color: getCssVariableValue('--text-color'),
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: getCssVariableValue('--text-color'),
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: getCssVariableValue('--text-color'),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '48px',
          color: getCssVariableValue('--accent-color'),
        },
        containedAccent: {
          backgroundColor: getCssVariableValue('--accent-color'),
          color: '#000000',
          '&:hover': {
            backgroundColor: getCssVariableValue('--accent-color'),
          },
        },
        wide: {
          width: '100%',
          padding: '12px 24px',
        },
      },
    },
  },
});

export default theme;

