
import React from 'react';

const HexContainer = ({ title, text, size = '100%', number }) => {
  return (
    <div style={{ textAlign: 'center', margin: '20px', width: size, height: 'auto', position: 'relative' }}>
      <svg
        viewBox="0 0 110 125"
        style={{ width: '100%', height: 'auto' }}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Number in the Top Left Corner */}
        <text
          x="10"
          y="15"
          fontSize="10"
          fill="var(--accent-color)"
          fontWeight="bold"
        >
          {number}
        </text>
        {/* Larger Outer Edge */}
        <polygon
          points="50,2 107,30 107,90 50,105 5,80 2,34"
          style={{ fill: 'none', stroke: '#ffa500', strokeWidth: 2 }}
        />
        {/* Main Hexagon with White Fill */}
        <polygon
          points="50,10 100,35 100,85 50,110 0,85 0,35"
          style={{ fill: 'var(--background-light)', stroke: 'none' }}
        />
        {/* Smaller Edge */}
        <polygon
          points="50,10 100,35 100,85 50,110 0,85 0,35"
          style={{ fill: 'none', stroke: 'var(--accent-color)', strokeWidth: 2 }}
        />
        {/* Body Text with Multiline Support */}
        <foreignObject x="0" y="15" width="100" height="90">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '6.5px',
              height: '100%',
              width: '100%',
              wordWrap: 'break-word',
              padding: '10px', // Add some padding for better spacing
              boxSizing: 'border-box',
            }}
          >
            {text}
          </div>
        </foreignObject>
      </svg>
    </div>
  );
};

export default HexContainer;

