import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import AboutPage from './AboutPage';
import ContactPage from './ContactPage';
import ServicesPage from './ServicesPage';
import HowPage from './HowPage';
import HeroPage from './HeroPage';

// Assuming adjustViewportHeight is imported from theme.js
import { adjustViewportHeight } from '../theme';  // Update the path according to your project structure

const HomePage = () => {
  const [heightCalculated, setHeightCalculated] = useState(false);

  useEffect(() => {
    // Call adjustViewportHeight from theme.js
    adjustViewportHeight();
    setHeightCalculated(true);

    // Add event listeners for resizing and orientation changes
    window.addEventListener('resize', adjustViewportHeight);
    window.addEventListener('orientationchange', adjustViewportHeight);

    return () => {
      window.removeEventListener('resize', adjustViewportHeight);
      window.removeEventListener('orientationchange', adjustViewportHeight);
    };
  }, []);

  if (!heightCalculated) {
    return null; // Wait until height is calculated before rendering
  }

  return (
    <div 
      id="scroll-container"
      style={{ 
        width: '100%', 
        scrollSnapType: 'y mandatory', // Enforces snapping behavior
        overflowY: 'scroll', 
        height: 'var(--router-height)', 
        backgroundColor: 'var(--background-gray)',
        scrollBehavior: 'smooth', // Smooth scrolling between sections
        scrollPadding: '0px 0px 1px 0px', // Adds a small padding to smooth the snap transition
      }}
    >
      <Box 
        id="hero" 
        style={{ 
          height: 'var(--router-height)', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          scrollSnapAlign: 'start', // Snap to the top of the section
          scrollSnapStop: 'always',  // Always stop at this section even on fast scroll
        }}
      >
        <HeroPage />
      </Box>
      <Box 
        id="services" 
        style={{ 
          height: 'var(--router-height)', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          scrollSnapAlign: 'start', 
          scrollSnapStop: 'always',
        }}
      >
        <ServicesPage />
      </Box>
      <Box 
        id="how" 
        style={{ 
          height: 'var(--router-height)', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          scrollSnapAlign: 'start', 
          scrollSnapStop: 'always',
        }}
      >
        <HowPage />
      </Box>
      <Box 
        id="about" 
        style={{ 
          height: 'var(--router-height)', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          scrollSnapAlign: 'start', 
          scrollSnapStop: 'always',
        }}
      >
        <AboutPage />
      </Box>
      <Box 
        id="contact" 
        style={{ 
          width: '100%', 
          height: 'var(--router-height)', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          scrollSnapAlign: 'start', 
          scrollSnapStop: 'always',
        }}
      >
        <ContactPage />
      </Box>
    </div>
  );
};

export default HomePage;

